<template>
  <div>
    <b-row>
      <b-col
        v-for="admissionForm in admissionForms"
        :key="admissionForm.id"
        :cols="admissionForm.cols"
      >
        <b-form-group>
          <label :for="`admission-form-${admissionForm.id}`">
            {{ admissionForm.key }}
          </label>
          <b-form-input
            :type="admissionForm.type"
            placeholder="Descrever"
            :id="`admission-form-${admissionForm.id}`"
            v-model="admissionForm.value"
            @input="debounceSaveAdmissionForm(admissionForm)"
          />
        </b-form-group>
        <hr />
      </b-col>
    </b-row>
    <div v-if="isLoading">
      <b-skeleton width="100%" v-for="i in 3" :key="i" class="mb-2" />
    </div>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import debounce from 'lodash/debounce'
export default {
  name: 'AdmissionForm',
  props: {
    surgeryInformationId: String,
    patientId: String
  },
  data: () => ({
    admissionForms: [],
    isLoading: false,
    clinic: getCurrentClinic()
  }),
  async created() {
    await this.getSurgeryAdmissionForms()
    this.debounceSaveAdmissionForm = debounce(
      this.debounceSaveAdmissionForm,
      800
    )
  },
  methods: {
    async getSurgeryAdmissionForms() {
      this.isLoading = true
      try {
        const { data } = await this.api.getSurgeryAdmissionForms(
          this.surgeryInformationId,
          this.clinic.id
        )
        this.admissionForms = data.map(admissionForm => {
          const { key } = admissionForm
          admissionForm.type = ['Altura', 'Peso'].includes(key)
            ? 'number'
            : 'text'
          if (
            [
              'Altura',
              'Peso',
              'Uso aparelho, órtese ou prótese móvel',
              'Informações sobre jejum'
            ].includes(key)
          ) {
            admissionForm.cols = 6
          } else if (
            [
              'Pressão arterial',
              'Respiração',
              'Batimentos cardíacos',
              'Glicemia'
            ].includes(key)
          ) {
            admissionForm.cols = 3
          } else {
            admissionForm.cols = 12
          }
          return {
            ...admissionForm
          }
        })
        this.admissionForms = data
      } catch (error) {
        this.$toast.error('Erro ao carregar ficha de admissão')
      } finally {
        this.isLoading = false
      }
    },
    async debounceSaveAdmissionForm(admissionForm) {
      await this.updateSurgeryAdmissionForm(admissionForm)
    },
    async updateSurgeryAdmissionForm(admissionForm) {
      try {
        await this.api.updateSurgeryAdmissionForm(admissionForm.id, {
          value: admissionForm.value
        })
      } catch (error) {
        this.$toast.error('Erro ao atualizar ficha de admissão')
      }
    }
  }
}
</script>
